import { useAxios } from './axiosUtil'
import axios from 'axios'

const v2FileUrl = '/v2/file'
const uploadUrl = `${v2FileUrl}/upload`
const downloadUrl = `${v2FileUrl}/download`

const downloadForClient = (response) => {
  const fileName = response.headers['content-disposition'].split('filename=').at(-1)
  const fileDownloadUrl = URL.createObjectURL(response.data)

  const link = document.createElement('a')

  // file object url 설정
  link.href = fileDownloadUrl

  // 다운로드 파일 이름 설정
  link.setAttribute('download', decodeURIComponent(fileName))

  // a 태그 추가
  document.body.appendChild(link)
  // 다운로드 실행
  link.click()

  // a 태그 및 object url 삭제
  document.body.removeChild(link)
  URL.revokeObjectURL(fileDownloadUrl)
}

export default class v2FileApi {
  _formData

  constructor({ boardName, boardId }) {
    this._formData = this._setFormData(boardName, boardId)
  }

  _setFormData(boardName, boardId) {
    const fd = new FormData()
    fd.append('boardName', boardName)
    fd.append('boardId', boardId)

    return fd
  }

  async getFileList() {
    const boardName = this._formData.get('boardName')
    const boardId = this._formData.get('boardId')

    return await useAxios(v2FileUrl, {
      method: 'GET',
      params: { boardName, boardId }
    })
  }

  async uploadFiles(files, type) {
    this._formData.append('type', type)
    for (const file of files) {
      this._formData.append('uploadFile', new File([file], encodeURIComponent(file.name), { type: file.type }))
    }

    const response = await useAxios(uploadUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: this._formData
    })

    if (!response.ok) return response.data
    return response
  }

  async downloadFile(f, type) {
    try {
      const response = await axios(downloadUrl, {
        method: 'GET',
        params: { f, type },
        responseType: 'blob'
      })

      downloadForClient(response)
      return { ok: true }
    } catch (error) {
      if (error.response) return { code: 404, message: error.response.statusText }
      return error
    }
  }

  async deleteFile(fileUuid, type) {
    return await useAxios(`${v2FileUrl}/${fileUuid}`, {
      method: 'DELETE',
      params: { type }
    })
  }
}
