// INFO: 지재권게시판 수정, 보기
<script>
import { getLabPatent } from '@/api/platform/community'
import { patentUpdate } from '@/api/admin/ntis/ntisPatentAPI'

import V2FileApi from '@/api/v2FileApi'

export default {
  name: 'adminBoardPatentUpdate',
  data() {
    const boardModel = {
      patentResultID: '',
      patentResultTitle: '',
      patentRegistCountry: '',
      patentRegistNumber: '',
      patentRegistrant: '',
      patentYear: '',
      patentProjectID: '',
      patentRegistType: '',
      patentIprType: '',
      patentProjectYear: '',
      patentBudgetProjectNumber: '',
      patentBudgetProject: '',
      patentMinistryName: '',
      patentProjectTitle: '',
      patentPerformAgency: '',
      patentPerformAgent: '',
      patentSixTechnology: '',
      patentTechnologyRoadMap: '',
      patentScienceClass1: '',
      patentScienceClass2: '',
      patentScienceClass3: '',
      patentRegisterDate: '',
      patentApplyField: '',
      patentManPower: '',
      patentGrowLevel: '',
      patentSummary: ''
    }
    Object.seal(boardModel)
    const boardNo = Number(this.$route.params.id) // 파라미터 게시글 번호

    return {
      boardNo: boardNo,
      patentPageNo: 0,
      labNo: 0,
      boardData: null,
      boardModel,
      fileApi: null,
      attachedFiles: []
    }
  },
  methods: {
    // ********** 목록 버튼 **********
    goPatentList() {
      this.$router.push({
        path: `/admin/board/patent/${this.labNo}/${this.patentPageNo}`
      })
    },

    async getPatent() {
      const data = await getLabPatent(this.boardNo)
      data.patentYear = data.patentYear ? new Date(data.patentYear).getFullYear() : ''

      if (data.patentRegisterDate !== null) {
        data.patentRegisterDate = this.$getDate(data.patentRegisterDate)
      }
      return data
    },
    // ********** 저장 버튼 **********
    async savePatent() {
      for (const key in this.boardModel) {
        this.boardModel[key] = this.boardData[key]
      }

      this.boardModel.patentRegisterDate = new Date(this.boardModel.patentRegisterDate)
      this.boardModel.patentYear = new Date(this.boardModel.patentYear.toString())

      const result = await patentUpdate(this.boardModel, this.boardNo)
      if (result.code) return alert(result.message)

      alert('저장되었습니다.')
      return this.goPatentList()
    },

    async fileDownload(fileUuidName) {
      if (!confirm('파일을 다운로드 하시겠습니까?')) return

      const result = await this.fileApi.downloadFile(fileUuidName, 'files')
      if (result.code) alert(result.message)
    },
    async fileDelete(fileUuidName) {
      if (!confirm('파일을 삭제 하시겠습니까?')) return

      const result = await this.fileApi.deleteFile(fileUuidName, 'files')
      if (result.code) alert(result.message)

      alert('파일이 삭제되었습니다.')
      this.attachedFiles = this.attachedFiles.filter((item) => item.fileUuidName !== fileUuidName)
    },
    async fileUpload(files) {
      if (files.length === 0) return alert('첨부할 파일이 없습니다.')

      const result = await this.fileApi.uploadFiles(files, 'files')
      if (result.code) alert(result.message)

      this.$router.go()
    }
  },

  async mounted() {
    this.patentPageNo = Number(this.$route.params.page) // 지재권 게시판 페이지 번호
    this.labNo = Number(this.$route.params.labNo) // 지재권 게시판 페이지 번호

    this.fileApi = new V2FileApi({ boardName: 'patent', boardId: this.boardNo })

    this.boardData = await this.getPatent()
    this.attachedFiles = await this.fileApi.getFileList()
  }
}
</script>

<template>
  <!-- 내용 입력 시작 -->
  <div class="adminTable" v-if="boardData">
    <form action="#" method="post" id="patentUpdateForm" @submit.prevent="savePatent">
      <!-- 수정 불가 정보 -->
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 번호</th>
            <td><input type="text" name="labNo" v-model="boardData.labNo" placeholder="연구실 번호" disabled /></td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <span class="info_text">NTIS 정보</span>
          <tr>
            <th>출원등록명</th>
            <td>
              <input
                type="text"
                name="patentResultTitle"
                v-model="boardData.patentResultTitle"
                placeholder="출원등록명"
                disabled
                size="100"
              />
            </td>
            <th>출원등록인</th>
            <td><input type="text" name="patentRegistrant" v-model="boardData.patentRegistrant" placeholder="출원등록인" disabled /></td>
          </tr>
          <tr>
            <th>출원등록국</th>
            <td>
              <input type="text" name="patentRegistCountry" v-model="boardData.patentRegistCountry" placeholder="출원등록국" disabled />
            </td>
            <th>성과연도</th>
            <td>
              <input
                type="number"
                min="1900"
                max="2099"
                step="1"
                name="patentYear"
                v-model="boardData.patentYear"
                placeholder="성과연도"
                disabled
              />
            </td>
          </tr>
          <tr>
            <th>출원등록번호</th>
            <td>
              <input type="text" name="patentRegistNumber" v-model="boardData.patentRegistNumber" placeholder="출원등록번호" disabled />
            </td>
            <th>지재권구분</th>
            <td class="patentRegistTypes">
              <input
                type="radio"
                id="patentRegistTypeApply"
                v-model="boardData.patentRegistType"
                name="patentRegistType"
                value="출원"
                disabled
              />
              <label for="patentRegistTypeApply">출원</label>
              <input
                type="radio"
                id="patentRegistTypeEnroll"
                v-model="boardData.patentRegistType"
                name="patentRegistType"
                value="등록"
                disabled
              />
              <label for="patentRegistTypeEnroll">등록</label>
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <!-- 수정 가능 정보 -->
      <span class="info_text">추가입력정보</span>
      <table>
        <colgroup>
          <col width="10%" />
          <col width="50%" />
          <col width="10%" />
          <col width="50%" />
        </colgroup>
        <tbody>
          <tr>
            <th>출원등록일자</th>
            <td><input type="date" name="patentRegistDate" v-model="boardData.patentRegisterDate" placeholder="출원등록일자" /></td>
            <th>적용가능분야</th>
            <td><input type="text" name="patentApplyField" v-model="boardData.patentApplyField" placeholder="적용가능분야" /></td>
          </tr>
          <tr>
            <th>개발인력</th>
            <td><input type="text" name="patentManpower" v-model="boardData.patentManPower" placeholder="개발인력" /></td>
            <th>기술 성숙도 단계</th>
            <td>
              <select name="patentGrowLevel" id="patentGrowLevel" v-model="boardData.patentGrowLevel">
                <option value="select" selected>선택</option>
                <option value="1">1단계</option>
                <option value="2">2단계</option>
                <option value="3">3단계</option>
                <option value="4">4단계</option>
                <option value="5">5단계</option>
                <option value="6">6단계</option>
                <option value="7">7단계</option>
                <option value="8">8단계</option>
                <option value="9">9단계</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>요약정보</th>
            <td colspan="3">
              <textarea type="text" name="patentSummary" v-model="boardData.patentSummary" placeholder="요약정보"></textarea>
            </td>
          </tr>
          <tr>
            <th>첨부 파일</th>
            <td colspan="3">
              <label class="wf__attached-file-item" v-for="(file, i) in attachedFiles" :key="i">
                <input type="text" :value="file.fileName" disabled />

                <div class="wf__attached-file-item--buttons">
                  <button
                    type="button"
                    class="e-btn wf__btn btn-success"
                    :style="{ padding: '0px !important' }"
                    @click="fileDownload(attachedFiles[i].fileUuidName)"
                  >
                    <span class="material-icons-outlined"> file_download </span>
                  </button>
                  <button
                    type="button"
                    class="e-btn wf__btn btn-danger"
                    :style="{ padding: '0px !important' }"
                    @click="fileDelete(attachedFiles[i].fileUuidName)"
                  >
                    <span class="material-icons-outlined"> delete_forever </span>
                  </button>
                </div>
              </label>

              <div class="wf__attached-file-item" v-if="attachedFiles.length === 0">
                <input class="cursor-pointer" type="file" ref="attachFile" />
                <button
                  class="e-btn wf__btn btn-success"
                  :style="{ padding: '0px !important' }"
                  type="button"
                  @click="fileUpload($refs.attachFile.files)"
                >
                  <span class="material-icons-outlined"> file_upload </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- 버튼 구역 -->
      <div class="btnArea">
        <button type="button" class="e-btn wf__btn btn-primary" @click="goPatentList()">목록</button>
        <button type="submit" id="savePatentBtn" class="e-btn wf__btn btn-success ml-2">저장</button>
      </div>
    </form>
  </div>
  <!-- 내용 입력 끝 -->
</template>

<style scoped>
.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}
#patentGrowLevel {
  width: 150px;
  height: 30px;
  border: 1px solid #ededed;
  -webkit-appearance: none; /* 화살표 제거 for chrome */
  -moz-appearance: none; /* 화살'표 제거 for firefox */
  appearance: none;
  background: url('../../../../assets/images/icons/downArrowIcon.png') calc(100% - 5px) center no-repeat;
  background-size: 20px;
  padding: 5px 30px 5px 10px;
  color: #acacac;
  font-weight: 500;
}
#patentGrowLevel::-ms-expand {
  display: none; /* html selectbox 기본 스타일 제거 */
}
#patentGrowLevel option {
  background: #fff;
  color: #18a1e9;
  padding: 3px 0;
}
.patentRegistTypes > input:not(:first-child) {
  margin-left: 20px;
}
#patentUpdateForm textarea {
  width: 540px;
  height: 100px;
}
.btnArea {
  margin-top: 30px;
}

.wf__attached-file-item {
  display: flex;
  gap: 1em;
}
.wf__attached-file-item > input:first-child {
  max-width: max-content;
}
.wf__attached-file-item--buttons {
  display: flex;
  gap: 0.5em;
}
</style>
